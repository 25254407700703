.list {
    height: auto;
    width: 100%;
    background: var(--light-orange);
   
    

    &:nth-child(odd){
        background: white ;

        .list-container .list-content-box {
            flex-direction: row-reverse;
        }

    }

    .list-container {
        height: 100%;
        max-width: var(--max-width);
        max-width: 1300px;

        .list-content-box {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            

            

            .list-left {
                height: 100%;
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                




                ul {
                    list-style-type: none;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;

                 

                    li {
                        color: var(--text-color);
                        font-size: 16px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    button{
                        margin-top: 10px;
                    }

                    .icon {
                        padding-right: 10px;
                        font-size: 20px;
                        color: var(--orange);
                        display: flex;
                        align-items: center;
                        justify-content: center;


                    }
                }





            }

            .list-right {
                height: 100%;
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
              

                .img-box {
                    // height: 400px;
                    // width: 500px;
                    // background:white;
                    // border-radius: 20px;
                    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);
                    display: flex;
                    align-items: center;
                    justify-content: center;


                    .img {
                        height: 500px;
                        width: 650px;
                        background-size: contain !important;
                        // background: url('../assets/cbdimg-1.jpg');
                    }
                }

            }
        }
    }
}

@media only screen and (max-width:900px) {

    .list{
        padding: 10px 0px !important;

    .list-left {
        width: 100% !important;
        align-items: center !important;

        .list-items {
            max-width: 500px;
        }

    }

    .list-right {
        width: 100% !important;

    }


    .list-content-box {
        flex-direction: column-reverse !important;


    }
}

   


}

@media only screen and (max-width:500px) {
    .list{
        padding: 10px 0px !important;

    .img-box {
        width: 100% !important;
        aspect-ratio: none !important;
        height: 300px !important;
        
        .img{
            height: calc(100% - 30px) !important;
            width: calc(100% - 30px) !important;
        }
    }
}
}