.fea-parent {
    // width: 100vw; 
    // height: 100vh;
    height: 600px;
    margin-top: 70px;
  
    .fea-Swiper {
        // height: 100vh;
        height: 100%;
        width: 100svw;

        .swiper-button-prev {
            display: none;
        }

        .swiper-button-next {
            display: none;
        }

        .swiper-pagination-bullet {
            background-color: orangered;
        }
    }

    .fea-overlay {
        position: absolute;
        background: rgba(3, 3, 3, 0.523);
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .fea-slide1-text-global {
        max-width: 1200px;
        display: flex;
        justify-content: center;
        z-index: 200;
        color: white;
        position: relative;
        position: 10px;
        text-align: center;
    }

    .fea-slide-img {
        height: 100%;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 50px;
        z-index: -1;
    }

    .fea-slide1 {
        background-image: url('../Images/features/0_master\ layout\ bird\ eye\ 1.jpg');
    }

    .fea-slide2 {
        background-image: url('../Images/features/1_masterplan\ without\ buildings.jpg');
    }

    .fea-slide3 {
        background-image: url('../Images/features/2_post\ card\ temple.jpg');
    }

    .fea-slide4 {
        background-image: url('../Images/features/7_avenue.jpg');

    }
    



}



@media only screen and (max-width: 800px) {

    .fea-parent {
       
         .fea-slide1-text-global{
            h2{
                text-align: center;
                text-wrap: wrap!important;
             font-size: 20px;
               
            }
         }   
        }
    }




//    ----------------- feature-card----------------

.fea-card-parent {
    padding: 20px 0px;
    .fea-card-top-headding{
        text-align:center;
        padding-bottom: 20px;
    }


    .fea-card-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 1200px;
        gap: 40px;
        .card-box{
            width:333.33px;
            height:300px;
            display:flex;
            flex-direction: column;
            gap:10px;
            .card-img1{
                width:300px;
                height:250px;
                background-image: url('../Images/features/landscape.jpg');
                
            }
            .card-img2{
                width:300px;
                height:250px;
                background-image: url('../Images/features/temple.jpg');
                
            }
            .card-img3{
                width:300px;
                height:250px;
                background-image: url('../Images/features/clubhouse.jpg');
                
            }
            .card-img4{
                width:300px;
                height:250px;
                background-image: url('../Images/features/plyaground.jpg');
                
            }
            .card-img5{
                width:300px;
                height:250px;
                background-image: url('../Images/features/citizen.jpg');
                
            }
            .card-img6{
                width:300px;
                height:250px;
                background-image: url('../Images/features/environment.jpg');
                
            }
            .card-text{
                color:var(--dark-red);
                font-weight: 600;
                display:flex;
                justify-content: center;
                align-items: center;

            }
        }

    }
}