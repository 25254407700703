.hero-parent {
    // width: 100vw; 
    // height: 100vh;
    height: 600px;
    // margin-top: 70px;
  
    .mySwiper {
        // height: 100vh;
        height: 100%;
        width: 100svw;

        .swiper-button-prev {
            display: none;
        }

        .swiper-button-next {
            display: none;
        }

        .swiper-pagination-bullet {
            background-color: orangered;
        }
    }

    .overlay {
        position: absolute;
        background: rgba(3, 3, 3, 0.523);
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .slide1-text-global {
        max-width: 1200px;
        display: flex;
        justify-content: center;
        z-index: 200;
        color: white;
        position: relative;
        position: 10px;
        text-align: center;
    }

    .slide-img {
        height: 100%;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 50px;
        z-index: -1;
    }

    .slide1 {
        background-image: url('../Images/contact/3_postcard\ phase\ 3.jpg');
    }

    .slide2 {
        background-image: url('../Images/contact/4_postcard\ sunset.jpg');
    }

    .slide3 {
        background-image: url('../Images/contact/5_postcard\ open\ space.jpg');
    }

    .slide4 {
        background-image: url('../Images/contact/6_school.png');

    }
    .slide5 {
        background-image: url('../Images/contact/0_master\ layout\ bird\ eye\ 2.jpg');

    }
    .slide6 {
        background-image: url('../Images/contact/1_masterplan\ without\ buildings.jpg');

    }



}



@media only screen and (max-width: 800px) {

    .hero-parent {

       

         .slide1-text-global{
            h2{
                text-align: center;
                text-wrap: wrap!important;
             font-size: 20px;
               
            }
         }   
        }
    }
