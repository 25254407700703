.topheading{
    height: auto;
    width: 100%;
    background: white;
    padding: 40px 0px;
    // margin-top: 60px;

    .topheading-container{
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       

        

        .down{
            
            width: 100%;
            text-align: center;
        }
    }
}