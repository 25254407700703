.residencial-parent-top {
    height: auto;
    aspect-ratio: 1920 /600;
    background-image: url('../Images/Residental/residencial-map.png');
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 70px;

    .overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.192);
        z-index: 5;
    }
}


.residencial-parent-bottom {
   

    .residencial-container-bottom {

        display: flex;
        justify-content: center;
        flex-direction: column;

        .resi-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .bottom-list {
                display: flex;
                align-items: center;
                gap: 60px;

                a {
                    text-decoration: none;
                    color: var(--text-color);
                }

                .active {
                    color: var(--dark-red);
                    border-bottom: 2px solid;
                }
            }

        }
    }
}


// section 3

.layout-page {
    // height: 600px;
    padding: 50px 0px 100px 0px;




    .layout-page-box {
        display: flex;
        gap: 30px;


        .layout-left {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;




            .layout-content {
                height: auto;
                width: 80%;
                aspect-ratio: 1;
                background-color: var(--orange);
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px;
                flex-direction: column;

                h3 {
                    color: white;
                }

                p {
                    color: white;
                    opacity: 0.7;
                    text-align: justify;
                }
            }
        }

        .layout-right {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;




            .layout-img {
                height: auto;
                width: 80%;
                aspect-ratio: 1;
                background: url("../assets/r1.webp");
                border-radius: 20px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.192);
            }
        }
    }

}





.r1 {
    background: url("../assets/r1.webp") !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}

.r2 {
    background: url("../assets/r2.webp") !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;



}

.r3 {
    background: url("../assets/r3.webp") !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;

}

.r4 {
    background: url("../assets/r4.webp") !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;

}

.r5 {
    background: url("../assets/r5.webp") !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;

}






#root>div>div.download.parent {
    margin-top: 0px !important;
}



.mob-swiper {
    height: auto;

    .mob-swiper-cont {

        .swiper {
            width: 100%;
            height: 100%;
        }

        .swiper-slide {
            padding: 30px 0px ;
            text-align: center;
            font-size: 18px;
            background: #fff;

            /* Center slide text vertically */
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 20px;

            .swiper-top{
                width: 600px;
                aspect-ratio: 4/3;
                border-radius: 10px !important; 
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.192);
                
            }

            .swiper-bottom{
                width: 600px;
                aspect-ratio: 2/1;
                background: var(--orange);
                border-radius: 10px !important;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px;
                flex-direction: column;

                h3 {
                    color: white;
                }

                p {
                    color: white;
                    opacity: 0.7;
                    text-align: justify;
                }

            }

            


        }

    }
}

@media only screen and (max-width: 1000px) {
    .residencial-parent-bottom {
        display: none !important;
    }

    .layout-page{
        display: none !important;
    }








}


@media only screen and (min-width: 1001px) {
.mob-swiper{
    display: none !important;
}
}


.swiper-button-next , .swiper-button-prev{
    display: none !important;
}

.swiper-pagination-bullet{
    background: var(--orange) !important;
}




@media only screen and (max-width: 600px) {
.swiper-top ,.swiper-bottom{
    width: 100% !important;
}

}