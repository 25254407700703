.cbd-heading{
    margin-top: 100px;
}

.cbd-bg{

    .list {
        &:nth-child(odd){
            background: white ;
        }
        &:nth-child(even){
            background: #ffefe6 ;
        }
    }

}