.toolbox
{
    position: fixed;
    right: 1%;
    bottom: 5%;
    z-index: 10001231230;
    display: flex;
    justify-content: center;
    align-items: center;

    button 
    {
       
        font-size: 20px;
        padding: 4px;
        cursor: pointer;
        color: var(--white);
        border-radius: 5px;
        transition: var(--trans);
        background: url('../assets/toggle-btn.png');
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        background: var(--dark-red);
        border: none;

        .img{

            background: url('../assets/toggle-btn.png');
            height: 100%;
            width: 100%;
        }

        &:hover 
        {
            transform: translateY(-5px);
        }
    }
}