.download{
    height: auto;
    width: 100%;
    background: var(--light-orange);
    padding: 40px 0px;
    margin-top: 70px;
    

    .download-container{

        height: 100%;
        max-width: var(--max-width);

       

        .content-box{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2{
                height: 50%;
                width: 100%;
                display: flex;
                padding-bottom: 20px;
                align-items: center;
                justify-content: center;
    
               
            }
            

            .phases-box{
                height: 50%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 30px;
                
                .btn{
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: stretch;
                    justify-content: center;
                    
                    gap: 20px;

                    button{
                        background: transparent;
                        border-radius: 5px;
                        border: none;
                        border: 3px solid var(--dark-red);
                        color: var(--dark-red);
                        font-size: 18px;
                        font-weight: 600;
                        padding: 10px 0px;
                        width: 180px;

                        &:hover{
                            color: white;
                            background: var(--dark-red);
                        }

                    }
                }
                
            }

        }

    }
}

@media only screen and (max-width:800px){
    .btn{
        flex-wrap: wrap;
    }
}