.background {
    height: 100vh;
    width: 100vw;
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.34);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    margin: 60px 0px;

    .pop-box {
        background: var(--light-orange);
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.225);
        position: relative;
        width: 500px;
        margin-bottom: 30px;
        height: 90svh;
        overflow-y: scroll;

        .head {
            padding-bottom: 20px;
            position: relative;

            .close {
                position: absolute;
                top: 0px;
                right: 0px;
                height: 40px;
                width: 40px;
                background: var(--dark-red);
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                cursor: pointer;

                &:hover {
                    background-color: var(--orange);
                }
            }

            .line {
                height: 1px;
                width: 100%;
                background: rgba(0, 0, 0, 0.253);
                margin-top: 20px;
            }
        }

        .main {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .form-parent {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .contact-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: auto;
                    // border: 1px solid black;
                    width: fit-content;
                    padding: 5px 20px;
                    border-radius: 50px;
                    background: var(--light-orange);
                    color: var(--orange);
                    border: none;
                }

                .form {
                    border-radius: 5px 5px 0px 0px;
                    padding: 0px 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    width: 100%;
                    position: relative;
                    margin-top: 6 0px;

                    .form-close-btn {
                        font-size: 30px;
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        cursor: pointer;
                    }

                    .form-group {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        // align-items: center;
                        // justify-content: center;
                        gap: 5px;
                        position: relative;

                        label {
                            color: var(--text-color);
                            font-size: 16px;

                        }

                        .form-input {
                            border: 1px solid rgba(148, 147, 147, 0.26);
                            padding: 10px;
                            border-radius: 5px;
                            box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.043);
                            width: 350px;
                            height: auto;
                            outline: none;

                            &:focus {
                                border: 1px solid var(--dark-red);
                            }
                        }
                    }

                    .button-parent {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .btn-submit {
                            border: none;
                            padding: 10px 20px;
                            background: var(--dark-red);
                            color: var(--light-orange);
                            cursor: pointer;
                            border-radius: 5px;
                        }
                    }
                }
            }

            input {
                outline: none;
                border: 1px solid rgba(0, 0, 0, 0.253);
                border-radius: 5px;
                padding: 10px 20px;
                width: 100%;

            }

            button {
                border: none;
                background: rgb(65, 92, 243);
                border-radius: 5px;
                color: white;
                width: 100px;
            }
        }


    }
}

@media only screen and (max-width:550px) {
    .pop-box {
        width: 95% !important;
    }
}

@media only screen and (max-width:450px) {
    .pop-box {
        width: 95% !important;

        .form-input {
            width: 350px !important;
        }
    }
}

@media only screen and (max-width:430px) {
    .pop-box {
        width: 95% !important;

        .form-input {
            width: 300px !important;
        }
    }
}

@media only screen and (max-width:400px) {
    .pop-box {
        width: 95% !important;
        overflow-y: scroll !important;

        .form-input {
            width: 300px !important;
            padding: 5px !important;
        }
    }
}

@media only screen and (max-width:380px) {
    .pop-box {
        width: 95% !important;
        // padding: 10px !important;
        overflow-y: scroll !important;

        .form-input {
            width: 280px !important;
            padding: 5px !important;
        }
    }
}

@media only screen and (max-width:360px) {
    .pop-box {
        width: 95% !important;
        // padding: 10px !important;
        overflow-y: scroll !important;

        .form-input {
            width: 270px !important;
            padding: 5px !important;
        }
    }
}
