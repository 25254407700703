:root {
  --white: #ffffff;
  --red: #ea1f25;
  --dark-red: #bc060c;
  --dark-red2: #692c27;
  --orange: #e74b23;
  --light-orange: #ffefe6;
  --text-color: #949494;
  --black: #1a1a1a;
  --max-width: 1400px;
  --transition: all 0.5s ease-in-out;
  --rad:20px;

}


*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  // border : 1px solid green;
  font-family: 'Poppins', sans-serif !important;
}

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




h1 {
  font-size: 45px;
}

h2 {
  font-size: 38px;

}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

P {
  font-size: 16px;
  color: var(--text-color);
  font-weight: 400;
  line-height: 24px;
  // text-align: justify;
  text-justify: distribute;
  text-align-last: left;

}

@media only screen and (max-width: 820px) {

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

}

@media only screen and (max-width: 420px) {

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 14px;


  }

}

.parent {
  width: 100svw;

  // height: 100svh;




  .container {
    height: 100%;
    max-width: var(--max-width);
    margin: auto;
    padding: 0px 20px;

  }


}

a {
  font-size: 18px;
  text-decoration: none !important;
  color: black;
}

@media only screen and (max-width: 1000px) {

  .container-large,
  .container-small {
    padding: 0px 10px !important;
  }
}

.btn-active {

  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  color: white;
  background-color: brown;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 5px;
  border: none;

  &:hover {
    //   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);
    background: var(--dark-red);
    transform: scale(1.05);

  }

}


.bg-img-cover {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;
}

.bg-img-contain {
  background-position: center center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;
}


body {
  overflow-x: hidden;
  width: 100vw;

}

.top-p{
  // background: red;
  margin-right: 30px;
}