.newsdiv {
  margin-top: 80px;
}

.news {
  height: auto;

  .news-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .mySwiper {
      height: 100%;
      width: 100%;

      .swiper {
        width: 100%;
        height: 100%;

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;

          /* Center slide text vertically */
          display: flex;
          justify-content: center;
          align-items: center;

          .swiper-pagination-clickable .swiper-pagination-bullet {
            background-color: red !important;
          }
        }
      }
    }

    .news-content-box {
      min-height: 300px;
      width: 100%;
      padding: 0px 0px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);
      margin: 30px;
      position: relative;
      background: white;
      gap: 30px;

      .new {
        height: 30px;
        width: 120px;
        background: var(--dark-red);
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          text-align: center;
          border: none;
          color: white;
          font-weight: 500;
        }
      }

      .news-left {
        height: 100%;
        width: 30%;
        display: flex;
        align-items: left;
        justify-content: flex-start;

        .news-img {
          height: 300px;
          width: 100%;
          border-radius: 10px 0px 0px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);

          .img {
            height: 100%;
            width: 100%;
            border-radius: 10px 0px 0px 10px;
            // border-radius: 10px;
            background: url("../assets/cbdimg-1.jpg");
          }
        }
      }

      .news-right {
        height: 100%;
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-right: 20px;

        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          text-align: justify;
          gap: 20px;
        }
      }
    }
  }

  .newsp {
    background-color: var(--light-orange);
    padding: 30px 0px;
    margin-top: 60px;

    h2 {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .news-left {
    width: 40% !important;
  }
  .news-right {
    width: 60% !important;
  }
}

@media only screen and (max-width: 1000px) {
    .news-content-box {
        flex-direction: column !important;
    }
    .news-left {
        width: 100% !important;

        .news-img, .img {

            border-radius:10px 10px 0px 0px !important;
        }
      }
      .news-right {
        width: 100% !important;
        padding: 20px !important;
        align-items: center !important;
      }
  
}

@media only screen and (max-width: 700px) {
   .news-content-box{
    margin: 30px 0px !important;
   }
   .content{
    h5{
        text-align: left !important;
    }
   }
}