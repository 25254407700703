.home-card-parent {
    padding: 30px 0px;


    .home-card-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 1200px;
        gap: 40px;
        .card-box{
            display:flex;
            flex-direction: column;
            gap:10px;
            .card-img1{
                width:300px;
                height:250px;
                background-image: url('../Images/home/residential.jpg');
                
            }
            .card-img2{
                width:300px;
                height:250px;
                background-image: url('../Images/home/commercial.jpg');
                
            }
            .card-img3{
                width:300px;
                height:250px;
                background-image: url('../Images/home/school.jpg');
                
            }
            .card-img4{
                width:300px;
                height:250px;
                background-image: url('../Images/home/hospital.jpg');
                
            }
            .card-img5{
                width:300px;
                height:250px;
                background-image: url('../Images/home/environment.jpg');
                
            }
            .card-img6{
                width:300px;
                height:250px;
                background-image: url('../Images/home/economic.jpg');
                
            }
            .card-text{
                color:var(--dark-red);
                font-weight: 600;
                display:flex;
                justify-content: center;
                align-items: center;

            }
        }

    }
}