.contact-parent-s2 {
    background: var(--light-orange);
    height: 100%;
    margin-top: 70px;
    padding: 20px 0px;

    .contact-container-s2 {
        width: 100%;
        display: flex;
        gap: 20px;
        justify-content: space-evenly;
        flex-direction: column;

        .contact-top {
            width: 100%;
        }

        .swiper {
            width: 100%;
            height: 280px;
        }

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: var(--white);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        }

        .swiper-slide-img1 {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-image: url('../Images/contact/3_postcard\ phase\ 3.jpg');
        }
        .swiper-slide-img2{
            background-image: url('../Images/contact/4_postcard\ sunset.jpg');

        }
         .swiper-slide-img3{
            background-image: url('../Images/contact/5_postcard\ open\ space.jpg');

        }
         .swiper-slide-img4{
            background-image: url('../Images/contact/6_school.png');

        }

        .contact-bottom {
            width: 100%;
            display: flex;
            gap: 20px;

            .broucher-left {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .broucher-left-card {
                    height: 300px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    background-color: white;
                    gap: 50px;
                    padding: 20px;
                    border-radius: 5px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

                    .broucher-backgoud-img {
                        border: 1px solid rgba(0, 0, 0, 0.192);
                        height: 100%;
                        width: 40%;
                        border-radius: 5px;  
                        background-image: url('../Images/contact/bg\ main\ page\ 3.jpg');                   
                    }

                    .broucher-content {
                        display: flex;
                        flex-direction: column;
                        gap: 25px;
                        line-height: 25px;
                    }
                }
            }



            .virtual-right {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;


                .broucher-backgoud-img {
                    // border: 1px solid rgba(0, 0, 0, 0.192);
                    height: 300px;
                    width: 100%;
                    border-radius: 5px;
                    background-color: white;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .coming-soon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color:var(--dark-red);
                    }

                }
            }

        }
    }
}

// media query for section2
// @media screen and (max-width: 1120px) {

//     .broucher-content {
//         h3 {
//             font-size: 20px !important;
//         }

//     }
// }

// @media screen and (max-width: 1000px) {
//     .contact-container-s2 {
//         display: flex;
//         flex-direction: column;
//         gap: 40px !important;
//         justify-content: center;
//         align-items: center;

//         .broucher-left {
//             .broucher-backgoud-img {
//                 border: 1px solid rgba(0, 0, 0, 0.192);
//                 height: 170px !important;
//                 width: 200px !important;
//                 border-radius: 5px;
//             }
//         }

//         .broucher-middle-line {
//             display: none;
//         }

//         .virtual-right {
//             width: 50% !important;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: center;
//             gap: 10px;

//             .broucher-backgoud-img {
//                 // border: 1px solid rgba(0, 0, 0, 0.192);
//                 height: 170px !important;
//                 width: 200px !important;
//                 border-radius: 5px !important;
//                 background-color: white !important;
//                 box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);
//             }
//         }
//     }
// }

// @media screen and (max-width:700px) {
//     .contact-parent-s2 {
//         background: var(--light-orange);

//         .contact-container-s2 {

//             .broucher-left {
//                 width: 100%;
//             }

//             .virtual-right {
//                 width: 50% !important;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: center;
//                 align-items: center;
//                 gap: 10px;

//                 .broucher-backgoud-img {
//                     // border: 1px solid rgba(0, 0, 0, 0.192);
//                     height: 170px !important;
//                     width: 200px !important;
//                     border-radius: 5px !important;
//                     background-color: white !important;
//                     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);
//                 }
//             }
//         }
//     }
// }

@media screen and (max-width:1000px) {
    .contact-bottom {
        flex-direction: column !important;

        .broucher-left {
            width: 100% !important;
        }

        .virtual-right {
            width: 100% !important;
        }
    }
}

@media screen and (max-width:500px) {
    .broucher-left-card {
        flex-direction: column !important;
        gap: 20px !important;
        .broucher-backgoud-img {
            width: 100% !important;
        }
        
    }
}