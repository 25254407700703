.login-parent {
    height: 500px;
    padding-top: 90px;
    margin: 80px 0px;

    .login-container {
        width: 30%;
        height: 100%;
        border-radius: 5px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        // gap: 20px;
        position: relative;
        
        .close {    
            position: absolute;
            top: 10px;
            right: 10px;
            height: 40px;
            width: 40px;    
            background:var(--dark-red);
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            cursor: pointer;
    
            // &:hover {
            //   background-color: rgb(110, 149, 154);
            // }
          }
       
        .login-div-input {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 80%;

            .login-input {
                padding: 7px;
                border: none;
                text-decoration: none;
                background-color: rgba(228, 222, 222, 0.363);
            }
        }

        .forgot-pass {
            color: var(--text);

            .for-pass-span {
                color:#636262;
                cursor: pointer;
                text-decoration: none;
                
            }

            :hover {
                color:var(--dark-red);
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .login-parent {
        .login-container {
            width: 50%;
        }
    }
}

@media screen and (max-width: 500px) {
    .login-parent {
        .login-container {
            width: 70%;
        }
    }
}

@media screen and (max-width: 400px) {
    .login-parent {
        .login-container {
            width: 80%;
        }
    }
}