.marquee-container {
    width: 100%;
    overflow: hidden;
    margin-top:80px;
    height:fit-content;
    background:var(--black);
    
  }
  
  a.marq{
    
    // animation: marquee 60s linear infinite; /* Adjust duration as needed */

   color:white;
   padding: 200px 0px 10px 0px;

  }
  
  @keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }