.blogtemp{
    min-height: 100svh;
    width: 100svw;
    background: var(--white);
    z-index: 100;
    margin-top: 80px;

    .content-width{
        max-width: 1200px;
        margin: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .topbox{
            width: 100%;
            height:500px ;
            display: flex;
            margin-top: 20px;
            border-radius: calc(var(--rad)/2);
            overflow: hidden;
            gap: 10px;
            flex-direction: column;
            

            .imgbox{
                height: 500px;
                background: rgb(161, 161, 161);
                width: 100%;
                border-radius: none !important;
            }
            .detailsbox{
                height: 50px;
                background: var(  --light-orange);
                width: 100%;
                margin: auto;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 30px;
                gap: 20px;

                .date{
                    display: flex;
                    gap: 20px;

                    p{
                        font-weight: 500;
                    }

                    p.d{
                        color: var(--dark-red);
                        margin-left: -15px;
                    }
                }

                a{
                    color: var(--dark-red);
                }




            }
        }

        .content{

            h1{
                line-height: 110%;
                font-size: 30px;
                margin-top: 20px;
            }
            p{
                text-align: justify;
                margin-bottom: 50px;
            }

           
        }








    }


}


.line{
    height: 1px;
    background: rgba(0, 0, 0, 0.123);
    width: 100%;
    margin: 10px 0px;
}