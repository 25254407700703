.header-parent {
    height: 80px;
    // box-shadow: 0px 0px 10px 0px rgba(179, 163, 163, 0.24);
    position: fixed;
    top: 0;
    z-index: 999;
    background: #f4e9c9;
   

    .header-container {
        height: 100%;
        max-width: 1600px;
        display: flex;
        justify-content: space-between;
        position: sticky;

        .header-logo-div {
            display: flex;
            align-items: center;
            justify-content: start;
            height: 100%;
            gap: 10px;

            .h-logo {
                height: 60%;
            }

           

           
        }

        .header-logo-contain {
            color: var(--dark-red);
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0px;

            .logo-text{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h2{
                    font-size: 30px;
                }

                p{
                    color: black;
                    font-size: 18px;
                    font-weight: 600;
                }
                


                



            }
        }

        .nav-logo-div {
            .logo-nav {
                width: 50px;
                    height: 100%;
                    cursor: pointer;
                    color: var(--dark-red)
            }
            :hover{
                color: var(--dark-red);
                opacity:0.7;
             }
        }
    }
}

.nav-toggle {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 0vw;
    background: rgb(255, 255, 255);
    position: fixed;
    top: 0px;
    right: 0px;
    gap: 20px;
    overflow: hidden;
    transition: all 0.3s ease-in-out !important; 
    z-index: 100;

    &.active{
        width: 100vw;  
    }

    a {
        text-decoration: none;
        font-size: 30px;
        font-weight: 600;
        color: var(--text-color);
    }
    :hover{
       color: black;
    }

    .close{
        height: 50px;
        width: 50px;
        position: absolute;
        top: 80px;
        right: 20px;
        font-size: 40px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.699);
        
    }  
}



.pop {
    height: 50px;
    width: 150px;
    position: fixed;
    top: 200px;
    right: 0px;
    z-index: 1000000000;
    background-color: var(--dark-red);
    border-radius: 5px 0px 0px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    animation: pop-animation 1.5s ease-in-out infinite;

    .pop-icon {
        background-image: url('../assets/Artboard.png');
        width: 50px;
        height: 50px;
    }

    .pop-p {
        color: var(--light-orange);
        line-height: 18px;
    }

}
@keyframes pop-animation {
    0% {
        right: 0px;
    }

    50% {
        right: -10px;
    }

    100% {
        right: 0px;
    }
}


@media screen and (max-width:800px){

    .h-logo {
        // width: 80px;
        height: 55% !important;
    }

    .header-logo-contain {
        color: var(--dark-red);
        font-size: 20px !important;
        font-weight: 600 !important;
    }
}

@media screen and (max-width:600px){

    .h-logo {
        // width: 80px;
        height: 50% !important;
    }

    .header-logo-contain {
        color: var(--dark-red);
        font-size: 18px !important;
        font-weight: 600 !important;
    }
}

@media screen and (max-width:550px){

    .h-logo {
        // width: 80px;
        height: 45% !important;
        // height: 50% !important;
    }


    .header-logo-contain {
        color: var(--dark-red);
        font-size: 16px !important;
        // font-size: 20px !important;
        font-weight: 600 !important;
        // line-height: 20px !important;
    }
}

@media screen and (max-width:480px){    

    .h-logo {
        // width: 80px;
        height: 45% !important;
        // height: 50% !important;
    }

    .header-logo-contain {
        color: var(--dark-red);
        font-size: 17px !important;
        font-weight: 600 !important;
        line-height: 20px !important;

        // font-size: 19px !important;
        // font-weight: 600 !important;
        // line-height: 20px !important;
    }
}

@media screen and (max-width: 450px) {
    .header-logo-div {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;

        .h-logo {
          
            height: 40% !important;
        }

        .header-logo-contain {
            font-size: 14px !important;
        }
    }
}





    @media screen and (max-width: 700px) {
    
        .header-logo-contain {
            color: var(--dark-red);
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0px;
    
            .logo-text{
                display: flex !important;
                flex-direction: column !important;
                align-items: center !important;
                justify-content: center !important;
    
                h2{
                    font-size: 22px !important;
                }
    
                p{
                    color: black !important;
                    font-size: 15px !important;
                    font-weight: 600 !important;
                }
    
            }
        }
        
            
        }


        @media screen and (max-width: 500px) {
    
            .header-logo-contain {
                color: var(--dark-red);
                font-size: 20px;
                font-weight: 600;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 20px 0px;
        
                .logo-text{
                    display: flex !important;
                    flex-direction: column !important;
                    align-items: center !important;
                    justify-content: center !important;
        
                    h2{
                        font-size: 16px !important;
                    }
        
                    p{
                        color: black !important;
                        font-size: 14px !important;
                        font-weight: 600 !important;
                    }
                    
                }
            }
        
          
               
                
            }

 @media screen and (max-width: 400px) {
    
                .header-logo-contain {
                    color: var(--dark-red);
                    font-size: 20px;
                    font-weight: 600;
                    text-align: center !important;
                    display: flex !important;
                    align-items: flex-start !important;
                    justify-content: center !important;
                    margin: 0px 0px !important;
            
                    .logo-text{
                        display: flex !important;
                        flex-direction: column !important;
                        align-items: flex-start!important;
                        justify-content: center !important;
            
                        h2{
                            font-size: 15px !important;
                            padding-top: 23px !important;
                            margin-left: -18px !important
                        }
            
                        p{
                            color: black !important;
                            font-size: 10px !important;
                            font-weight: 600 !important;
                            margin-left: -18px !important
                        }
                        
                    }
                }
            
              
                   
 }  
 
 @media screen and (max-width: 380px) {
    
    .header-logo-contain {
        color: var(--dark-red);
        font-size: 20px;
        font-weight: 600;
        text-align: center !important;
        display: flex !important;
        align-items: flex-start !important;
        justify-content: center !important;
     

        .logo-text{
            display: flex !important;
            flex-direction: column !important;
            align-items: flex-start!important;
            justify-content: center !important;

            h2{
                font-size: 14px !important;
                padding-top: 23px !important;
                margin-left: -12px !important
            }

            p{
                color: black !important;
                font-size: 10px !important;
                font-weight: 600 !important;
                margin-left: -12px !important
            }
            
        }
    }

  
       
}   
    



