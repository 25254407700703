.about-parent-top {
  padding: 20px 0px 0px 0px;
  height: auto;
  margin-top: 10px;

  .about-container-top {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    

    .about-top-left {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 5;
      

      .about-top-img-box {
        height: 400px !important;
        width: 100%;
        // background: rgba(255, 0, 0, 0.269);
        // background-color: black;
        background: url(../Images/about/1.jpg);
        background-position: center;
        background-size: contain !important;
        background-repeat: no-repeat !important;

        // border-radius: 5px;
        // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.43);
        // border: 15px solid white;
        // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.43);
      }
    }
  }

  .about-top-right {
    width: 50%;
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    
    p {
      text-align: justify;
      letter-spacing: 0.7px;
      word-spacing: 1px;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .about-parent-top {
    height: auto;

    .about-container-top {
      flex-direction: column;

      .about-top-left {
        width: 100%;
      }

      .about-top-right {
        width: 100% !important;
        text-align: justify;
        align-items: center;
        // padding: 0px 30px;
        p {
          text-align: justify;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .about-parent-top {
    .about-container-top {
      flex-direction: column;

      .about-top-left {
        .about-top-img-box {
          // height:550px;
          // aspect-ratio: 1/1;
          // width:100%;
          // border: 10px solid white;
          background-size: cover !important;
          background-position: center center !important;
        }
      }

      .about-top-right {
        // padding: 0px 10px;
      }
    }
  }
}

// {/* ----------about-middle-section------------ */}

.about-parent-middle {
  background: var(--light-orange);
//   padding: 50px 0px;

  .about-container-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    // gap: 50px;
    // max-width: 1400px;
    // margin: auto;

    .about-middle-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
    //   padding-right: 50px;
    //   gap: 10px;
      p {
        text-align: justify;
        letter-spacing: 0.7px;
      word-spacing: 1px;
      }
    }

    .about-middle-right {
      width: 50%;
      // height: 400px;
      display: flex;
      justify-content: center;

      .middle-right-img {
        height: 300px !important;
        width: 100%;
        height: 400px !important;
        // background: rgba(255, 0, 0, 0.269);
        background: url(../Images/about/3.jpg);
        // border-radius: 5px;
        // border: 15px solid white;
        // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.43);
        background-size: contain !important;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .about-parent-middle {
    height: auto;

    .about-container-middle {
      flex-direction: column-reverse;
      gap: 50px;

      .about-middle-left {
        width: 100%;
        text-align: justify;
        align-items: center;
        padding: 0px 30px;
      }

      .about-middle-right {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .about-parent-middle {
    .about-container-middle {
      .about-middle-left {
        padding: 0px 10px;
      }

      .about-middle-right {
        width: 100%;

        .middle-right-img {
          background-size: cover !important;
          background-position: center center !important;
        }
      }
    }
  }
}

// {/* {/* ----------about-last-section------------ * */}

.about-last-parent {
  // padding: 50px 0px;

  .about-last-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;

    .about-last-left {
      width: 50%;
      // height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;

      .about-last-left-img {
        height: 300px !important;
        width: 100%;
        height: 400px !important;
        // background: rgba(255, 0, 0, 0.269);
        background: url(../Images/about/2.jpg);
        // border-radius: 5px;
        // border: 15px solid white;
        // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.43);
        background-size: contain !important;
      }
    }

    .about-last-right {
      width: 50%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
    //   padding-right: 50px;
    //   gap: 10px;
      p {
        text-align: justify;
        letter-spacing: 0.7px;
      word-spacing: 1px;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .about-last-parent {
    height: auto;

    .about-last-container {
      flex-direction: column;
      gap: 50px;

      .about-last-left {
        width: 100%;
      }

      .about-last-right {
        width: 100%;
        text-align: justify;
        align-items: center;
        padding: 0px 30px 30px 0px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .about-last-parent {
    .about-last-container {
      .about-last-left {
        width: 100%;
        .about-last-left-img {
          background-size: cover !important;
          background-position: center center !important;
        }
      }

      .about-last-right {
        width: 100%;
        text-align: justify;
        align-items: center;
        padding: 0px 10px 10px 0px;
      }
    }
  }
}
