.footer-parent {
    background-color: var(--dark-red2);
    padding-top: 30px;

    .footer-container {

        .f-top-heading-box {
            display: flex;
            justify-content: space-between;
            padding-bottom: 30px;

            .footer-headding {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                color: white;

                .flogo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                    width: 80px;
                    z-index: 90;
                    background: url('../assets/Artboard.png');
                }
            }

            .f-icon-box {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;

                .f-icon1 {
                    color: #316FF6;
                    border: 2px solid #316FF6;
                    border-radius: 100%;
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 35px;
                    width: 35px;
                    font-size: 20px;
                    text-align: center;

                    &:hover {
                        color: #316FF6;
                        background-color: transparent;
                    }
                }

                .f-icon2 {
                    color: #DD2A7B;
                    border: 2px solid #DD2A7B;
                    border-radius: 100%;
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 35px;
                    width: 35px;
                    font-size: 20px;
                    text-align: center;

                    &:hover {
                        color: #DD2A7B;
                        background-color: transparent;
                    }
                }

                .f-icon3 {
                    color: #E62117;
                    border: 2px solid #E62117;
                    border-radius: 100%;
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 35px;
                    width: 35px;
                    font-size: 20px;
                    text-align: center;

                    &:hover {
                        color: #E62117;
                        background-color: transparent;
                    }
                }

                .f-icon4 {
                    color: #0084B4;
                    border: 2px solid #0084B4;
                    border-radius: 100%;
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 35px;
                    width: 35px;
                    font-size: 20px;
                    text-align: center;

                    &:hover {
                        color: #0084B4;
                        background-color: transparent;

                    }
                }
            }
        }

     

        .footer-top {
            display: flex;
            justify-content: center;


            .title {
                color: rgba(249, 243, 243, 0.923);
                letter-spacing: 0.6px;
            }

            .sub-title {
                color: white;
                font-size: 18px;

            }

            a {
                text-decoration: none;
                color: white;
                font-size: 14px;

                &:hover {
                    color: grey;
                }
            }


            .column-pnumber {
                width: 25%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                text-decoration: none !important;
                color: white;
                gap: 10px;
                border-right: 1px solid white;
                padding-left: 30px;
                flex-direction: column;

                p {
                    color: white;
                }

                .phone-icon {
                    color: white;
                    border: 1px solid white;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    width: 30px;
                    font-size: 15px;

                    &:hover {
                        color: white;
                        background-color: var(--dark-red);
                    }
                }

                .f-icon-phone {
                    color: white;
                    border: 1px solid white;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    width: 30px;
                    font-size: 15px;

                    &:hover {
                        color: grey;
                        background-color: var(--dark-red);
                    }
                }


                .icon-address {
                    display: flex;

                    gap: 10px;

                    .icon-link {
                        font-size: 14px;
                        color: white;
                        font-weight: 400;
                        // line-height: 24px;
                    }

                    .f-icon1 {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px !important;
                        width: 30px !important;
                        font-size: 20px;
                        border-radius: 50%;
                        align-items: center;
                        color: white;
                        border-radius: 50px;
                        border: 1px solid white;

                        &:hover {
                            color: grey;
                            background-color: var(--dark-red);
                        }
                    }
                }
            }

            .column-first {
                width: 25%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-right: 1px solid white;
                padding: 0px 20px;
                text-decoration: none !important;
                color: white;
                gap: 5px;

                h5{
                    text-align: center;
                }
                h6{
                    text-align: center;
                    
                    color: #f79b1c;
                    width: 100%;
                    
                }

                p {
                    color: white;
                }
            }

            .column-second {
                width: 25%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-right: 1px solid white;
                padding: 0px 20px;
                text-decoration: none !important;
                gap: 5px;

                h5{
                    text-align: center;
                }
                h6{
                    text-align: center;
                    // background: orange;
                    width: 100%;
                    color: #f79b1c;
                }

                p {
                    color: white;
                    
                }


            }


            .column-third {

                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // border-right: 1px solid gray;
                padding: 0px 20px;
                text-decoration: none !important;
                gap: 5px;

                h5{
                    text-align: center;
                }

                h6{
                    text-align: center;
                    // background: white;
                    color:  #f79b1c;
                    width: 100%;
                }

                p {
                    color: white;
                    
                }




            }

            .column-fourth {
                background-color: white;
                padding: 0px 5px;
                border-radius: 10px;
                .column-logo{
                    background-image: url('../assets/nvm-logo.png');
                    height: 150px;
                    width: 150px;
                   
                }
            }
           
        }

        
       

        .footer-bottom {
            border-top: 1px solid white;
            padding-top: 8px ;
            margin-top: 25px;
            display: flex;
            // justify-content: center;
            justify-content: space-between;

            p {
                color: white;
                font-size: 10px;
                padding-bottom: 10px;
            }

            .footer-terms{
                padding: 4px 0px;
                color: white !important;
                font-size: 10px !important;
            }
        }
    }


}


@media only screen and (max-width: 1000px) {
    .footer-parent {

        .footer-container {


            .f-top-heading-box {
                flex-direction: column-reverse;
                gap: 10px;
                align-items: center;

                .f-sssc {
                    font-size: 16px !important;
                }

                .flogo {
                    height: 40px !important;
                    width: 55px !important;
                }

            }

            .footer-top {
                flex-direction: column;
                gap: 20px;
                


                .column-pnumber {
                    width: 100%;
                    align-items: center;
                    border-right: none;
                    border-bottom: 1px solid white;
                    padding: 10px;

                }

                .column-first {
                    // display: none;
                    width: 100%;
                    align-items: center;
                    border: none;
                    padding: 10px 10px;
                    border-bottom: 1px solid white;
                }


                .column-second {
                    // display: none;
                    width: 100%;
                    align-items: center;
                    border: none;
                    border-bottom: 1px solid white;
                    padding: 10px 10px;
                }

                .column-third {
                    // display: none;
                    width: 100%;
                    align-items: center;
                    border: none;
                    padding: 10px 10px;


                }

                .column-fourth {
                    // width: 100%;
                    padding: 0px 10px !important;
                    align-items: center;
                    padding: 10px 10px;

                    p {
                        text-align: center;
                    }


                }
            }

            .footer-bottom {
                flex-direction: column;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 10px;
            }


        }
    }



}



@media screen and (min-width: 350px) and (max-width: 1000px) {
    .footer-parent {
        .footer-container {

            .footer-top {
                .column-pnumber {
                    width: 80%;
                    margin: auto;
                }

                .column-first {
                    width: 80%;
                    margin: auto;
                }

                .column-second {
                    width: 80%;
                    margin: auto;
                }

                .column-third {
                    width: 80%;
                    margin: auto;
                }

                .column-fourth {
                    // width: 80%;
                    margin: auto;
                }

            }
        }
    }
}

